<template>
  <div>
    <vs-card v-if="orders.length == 0">
      <div slot="header">
        <p>Não há pedidos pendentes.</p>
      </div>
    </vs-card>

    <vs-card :key="index" v-for="(order, index) in orders">
      <div slot="header">
        <h3 :style="{ color: order.classification.color }">
          Pedido {{ order.code }} - [{{ order.classification.name }}]
        </h3>
      </div>

      <div class="card">
        <p>
          Solicitado em {{ order.created }} pelo usuário
          <strong>{{ order.createdUser.name }}</strong>
        </p>
        <p v-if="order.justification">
          Justificativa da urgência: {{ order.justification }}
        </p>
        <vs-divider></vs-divider>
        <p>Código: {{ order.items[0].project.code }}</p>
        <p :key="index" v-for="(item, index) in order.items">
          {{ item.project.name }} - [{{ item.qtd }} unidade(s)]
        </p>
      </div>

      <div slot="footer">
        <vs-button @click="openPopUp(order)" class="bt">Aprovar</vs-button>
        <vs-button @click="cancel(order)" v-if="hasPermissionToCancel"
          >Cancelar</vs-button
        >
      </div>
    </vs-card>
    <vs-popup :active.sync="showPopUp" title="Selecionar Projetista">
      Projetista
      <select
        name="designerId"
        v-validate="'required'"
        v-model="designerId"
        label="Projetista"
        class="w-full mb-6 select1"
        data-vv-as="Projetista"
      >
        <option
          :key="index"
          :value="item.id"
          v-for="(item, index) in userOptions"
        >
          {{ item.name }}
        </option>
      </select>
      <vs-button @click="aprove()" class="bt">Confirmar</vs-button>
    </vs-popup>
  </div>
</template>

    <script>
import siriusAPI from "../services";
import {
  notifyError,
  formatDateToLocale,
  notifySuccess,
} from "@/utils/helpers";
export default {
  data() {
    return {
      orders: [],
      hasPermissionToCancel: false,
      userOptions: null,
      loggedUser: null,
      showPopUp: false,
      designerId: null,
    };
  },

  created() {
    this.loadOrders();
    this.loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    const userProfile = JSON.parse(localStorage.getItem("userInfo")).profile
      .name;
    const profiles = ["Administrador"];
    this.hasPermissionToCancel = profiles.indexOf(userProfile);
  },

  methods: {
    loadUsers() {
      this.$vs.loading();
      new siriusAPI()
        .getUsersWithParams(null, this.loggedUser.unit.id, 3)
        .then((res) => {
          this.userOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de usuários " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    loadOrders() {
      this.$vs.loading();
      new siriusAPI()
        .getOrders(false, false, false, false, "", 1)
        .then((res) => {
          this.orders = res.data;
          this.orders = this.orders.map((order) => ({
            ...order,
            created: formatDateToLocale(order.createdAt),
          }));
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de pedidos " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    openPopUp(order) {
      this.loadUsers();
      this.orderSelected = order;
      this.showPopUp = true;
    },

    aprove() {
      if (!this.designerId) {
        notifyError(
          this,
          "Obrigatório selecionar o projetista para aprovar o pedido!"
        );
        return;
      }
      const data = { phaseId: 2, designerId: this.designerId }; /*APROVADO */
      this.$vs.loading();

      new siriusAPI()
        .updatePhase(this.orderSelected.uuid, data)
        .then(() => {
          notifySuccess(this, "Pedido aprovado com sucesso");
          this.$vs.loading.close();
          this.loadOrders();
          this.showPopUp = false;
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar aprovar o pedido: " + error);
        });
    },

    cancel(order) {
      const data = { phaseId: 10 }; /*cancelar */
      this.$vs.loading();

      new siriusAPI()
        .updatePhase(order.uuid, data)
        .then(() => {
          notifySuccess(this, "Pedido cancelado com sucesso");
          this.$vs.loading.close();
          this.loadOrders();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar cancelar o pedido: " + error);
        });
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.bt {
  margin-right: 1em;
}
</style>
